<template>
    <div>
        <div class="sec-banner" id="lessonBanner">
            <div class="swiper-container" ref="homeSwiper">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="carousel in bannerList" :key="carousel.id">
                        <img :src="carousel.imgUrl" />
                    </div>
                </div>
                <!-- 如果需要分页器 -->
                <div class="swiper-pagination"></div>
            </div>
        </div>
        <div class="w1703">
            <div class="imgListWrapper" style="display: none;">
                <div class="centerSolid">
                    <div class="singleWrap">
                        <img src="https://image.linggan.znzmo.com/manage/new_classify_bg/家装空间.jpg" alt="">
                        <a href="" class="mask">室内设计</a>
                    </div>
                    <div class="singleWrap">
                        <img src="https://image.linggan.znzmo.com/manage/new_classify_bg/建筑.jpg" alt="">
                        <a href="" class="mask">建筑景观</a>
                    </div>
                    <div class="singleWrap">
                        <img src="https://image.linggan.znzmo.com/manage/new_classify_bg/工装通用空间9.jpg" alt="">
                        <a href="" class="mask">平面设计</a>
                    </div>
                    <div class="singleWrap">
                        <img src="https://image.linggan.znzmo.com/manage/new_classify_bg/公共空间.png" alt="">
                        <a href="" class="mask">视频制作</a>
                    </div>
                    <div class="singleWrap">
                        <img src="https://image.linggan.znzmo.com/manage/new_classify_bg/办公空间.jpg" alt="">
                        <a href="" class="mask">工业建模渲染</a>
                    </div>

                </div>
            </div>
            <div class="course">
                <div class="adv-row">
                    <div class="row-col">
                        <div class="row-title">
                            <span class="adv-icon skill"></span>
                            <h3>必备技能</h3>
                        </div>
                        <ul class="row-list">
                            <li>
                                <a href="">3DMAX</a>
                            </li>
                            <li>
                                <a href="">VRay</a>
                            </li>
                            <li>
                                <a href="">AutoCAD</a>
                            </li>
                            <li>
                                <a href="">Corona</a>
                            </li>
                            <li>
                                <a href="">SketchUp</a>
                            </li>
                            <li>
                                <a href="">VRay 5.0</a>
                            </li>
                            <li>
                                <a href="">Photoshop</a>
                            </li>
                            <li>
                                <a href="">Enscape</a>
                            </li>
                            <li>
                                <a href="">VRay for SketchUp</a>
                            </li>
                            <li>
                                <a href="">SketchBook</a>
                            </li>
                            <li>
                                <a href="">UE4虚幻引擎</a>
                            </li>
                            <li>
                                <a href="">Pano2VR/全景</a>
                            </li>
                            <li>
                                <a href="">Marvelous Designer</a>
                            </li>
                            <li>
                                <a href="">IPAD手绘</a>
                            </li>
                            <li>
                                <a href="">Fstorm Render</a>
                            </li>
                            <li>
                                <a href="">Premiere</a>
                            </li>
                            <li>
                                <a href="">Unfold3D</a>
                            </li>
                            <li>
                                <a href="">MultiScatter</a>
                            </li>
                        </ul>
                    </div>
                    <div class="row-col">
                        <div class="row-title">
                            <span class="adv-icon cour"></span>
                            <h3>热门教程</h3>
                        </div>
                        <ul class="row-list">
                            <li>
                                <a href="">3DMAX</a>
                            </li>
                            <li>
                                <a href="">VRay</a>
                            </li>
                            <li>
                                <a href="">AutoCAD</a>
                            </li>
                            <li>
                                <a href="">Corona</a>
                            </li>
                            <li>
                                <a href="">SketchUp</a>
                            </li>
                            <li>
                                <a href="">VRay 5.0</a>
                            </li>
                            <li>
                                <a href="">Photoshop</a>
                            </li>
                            <li>
                                <a href="">Enscape</a>
                            </li>
                            <li>
                                <a href="">VRay for SketchUp</a>
                            </li>
                            <li>
                                <a href="">SketchBook</a>
                            </li>
                            <li>
                                <a href="">UE4虚幻引擎</a>
                            </li>
                            <li>
                                <a href="">Pano2VR/全景</a>
                            </li>
                            <li>
                                <a href="">Marvelous Designer</a>
                            </li>
                            <li>
                                <a href="">IPAD手绘</a>
                            </li>
                            <li>
                                <a href="">Fstorm Render</a>
                            </li>
                            <li>
                                <a href="">Premiere</a>
                            </li>
                            <li>
                                <a href="">Unfold3D</a>
                            </li>
                            <li>
                                <a href="">MultiScatter</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="recommed" style="display: none;">
                    <a href="">
                        <img src="https://img.3d66.com/focus/2022/20220105/dfa6eee9ca93a29862e641b04d5c78a6.png" alt="">
                    </a>
                    <a href="">
                        <img src="https://img.3d66.com/focus/2022/20220105/417aadf35350e68615d67adc1a46c376.png" alt="">
                    </a>
                    <a href="">
                        <img src="https://img.3d66.com/focus/2022/20220608/5e24ffea007f0a9421151dc1c9c63d56.png" alt="">
                    </a>
                    <a href="">
                        <img src="https://img.3d66.com/focus/2022/20220105/3b424afba4d56ba746f1e0bc2dcf0f19.png" alt="">
                    </a>
                    <a href="">
                        <img src="https://img.3d66.com/focus/2022/20220105/dfa6eee9ca93a29862e641b04d5c78a6.png" alt="">
                    </a>
                </div>
                <div class="educationFloorsWrap">
                    <div class="floor-item">
                        <div class="listImg">
                            <img src="	https://img.3d66.com/soft/2021/20210820/7f8bac100038564d0e3c254dffa68c10.jpg"
                                class="imgCon" alt="">
                            <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt="">
                            <div class="time">18分13秒</div>
                        </div>
                        <div class="listInfo">
                            <a href="" class="listInfoTitle">3DMAX室内效果图全程班-零基础到独立出图就业教程</a>
                            <div class="keInfo">
                                <div>3DMax</div>
                                <div class="view">
                                    <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png" alt="">1
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="floor-item">
                        <div class="listImg">
                            <img src="https://img.3d66.com/soft/2019/20190827/b1224cebb189c327145f99f27a58b4ce.jpg"
                                class="imgCon" alt="">
                            <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt="">
                            <div class="time">130节</div>
                        </div>
                        <div class="listInfo">
                            <a href="" class="listInfoTitle">3DMax建模技巧130讲教程</a>
                            <div class="keInfo">
                                <div>3DMax</div>
                                <div class="view">
                                    <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png" alt="">3
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="floor-item">
                        <div class="listImg">
                            <img src="https://img.3d66.com/zixue/202110/120/2021-10-28/8714e1c54d086cc197fb521f7144900c.jpg"
                                class="imgCon" alt="">
                            <!-- <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt=""> -->
                            <div class="time">30节</div>
                        </div>
                        <div class="listInfo">
                            <a href="" class="listInfoTitle">零基础7天学会3DMax2019课程</a>
                            <div class="keInfo">
                                <div>3DMax</div>
                                <div class="view">
                                    <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png" alt="">291
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="floor-item">
                        <div class="listImg">
                            <img src="https://img.3d66.com/zixue/202101/298/2021-01-03/a112e5b6afd9db110500201a0e749c1b.jpg"
                                class="imgCon" alt="">
                            <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt="">
                            <div class="time">5节</div>
                        </div>
                        <div class="listInfo">
                            <a href="" class="listInfoTitle">3DMax零基础入门到精通实例教程</a>
                            <div class="keInfo">
                                <div>3DMax</div>
                                <div class="view">
                                    <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png" alt="">62
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="floor-item">
                        <div class="listImg">
                            <img src="https://img.3d66.com/soft/2022/20220812/82f098307c56ec20ad0c6fe1f3d6ccdf.jpg"
                                class="imgCon" alt="">
                            <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt="">
                            <div class="time">5节</div>
                        </div>
                        <div class="listInfo">
                            <a href="" class="listInfoTitle">3DMax展览展示项目实战教程</a>
                            <div class="keInfo">
                                <div>3DMax</div>
                                <div class="view">
                                    <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png" alt="">110
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="floorWrapper">
                <div class="floor">
                    <div class="floorTitle">
                        <div class="floorTitleLeft">
                            <div class="title">软件课程大全</div>
                            <div class="desc"><span style="color: #e80000;">88+</span>软件入门课程</div>
                        </div>
                    </div>
                    <div class="soft-lists-wrap">
                        <ul class="soft-list"
                            :style="{ maxHeight:(showMore==1?'none':'110px'),overflow:(showMore==1?'auto':'hidden')}">
                            <li>
                                <a href="">
                                    <img src="https://img.3d66.com/soft/2020/20200424/0bf9077c3dfe7fd41f8cc90cad87f1e0.png"
                                        alt="">
                                    <div>
                                        <div class="txt">3DsMax</div>
                                        <p>共21节课程</p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src="https://img.3d66.com/soft/2020/20200424/5f12024f032e46191e7dc487d5e25299.png"
                                        alt="">
                                    <div>
                                        <div class="txt">CAD教程</div>
                                        <p>共298节课程 </p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src="https://img.3d66.com/soft/2019/20190910/8debd17b0e9d9013fade732886fa519c.png"
                                        alt="">
                                    <div>
                                        <div class="txt">Photoshop</div>
                                        <p>共70节课程 </p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src="https://img.3d66.com/soft/2020/20200717/93bac9897fe9563dbd5eb2e3a73aa79d.jpg"
                                        alt="">
                                    <div>
                                        <div class="txt">SketchUp</div>
                                        <p>共40节课程 </p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src="https://img.3d66.com/soft/2020/20200428/121e5a2b7f98035f81bbdc87bc4bcc00.jpg"
                                        alt="">
                                    <div>
                                        <div class="txt">Premiere</div>
                                        <p>共59节课程 </p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src="https://img.3d66.com/soft/2020/20200424/401822645579724da12b5a7c182a673e.png"
                                        alt="">
                                    <div>
                                        <div class="txt">Vray5.0</div>
                                        <p>共34节课程 </p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src="https://img.3d66.com/soft/2019/20190910/adf8d6bde473001d9fee80debe207858.png"
                                        alt="">
                                    <div>
                                        <div class="txt">Cinema 4D</div>
                                        <p>共70节课程 </p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src="https://img.3d66.com/soft/2020/20200424/0bf9077c3dfe7fd41f8cc90cad87f1e0.png"
                                        alt="">
                                    <div>
                                        <div class="txt">3DsMax</div>
                                        <p>共21节课程</p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src="https://img.3d66.com/soft/2020/20200424/5f12024f032e46191e7dc487d5e25299.png"
                                        alt="">
                                    <div>
                                        <div class="txt">CAD教程</div>
                                        <p>共298节课程 </p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src="https://img.3d66.com/soft/2019/20190910/8debd17b0e9d9013fade732886fa519c.png"
                                        alt="">
                                    <div>
                                        <div class="txt">Photoshop</div>
                                        <p>共70节课程 </p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src="https://img.3d66.com/soft/2020/20200717/93bac9897fe9563dbd5eb2e3a73aa79d.jpg"
                                        alt="">
                                    <div>
                                        <div class="txt">SketchUp</div>
                                        <p>共40节课程 </p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src="https://img.3d66.com/soft/2020/20200428/121e5a2b7f98035f81bbdc87bc4bcc00.jpg"
                                        alt="">
                                    <div>
                                        <div class="txt">Premiere</div>
                                        <p>共59节课程 </p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src="https://img.3d66.com/soft/2020/20200424/401822645579724da12b5a7c182a673e.png"
                                        alt="">
                                    <div>
                                        <div class="txt">Vray5.0</div>
                                        <p>共34节课程 </p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src="https://img.3d66.com/soft/2019/20190910/adf8d6bde473001d9fee80debe207858.png"
                                        alt="">
                                    <div>
                                        <div class="txt">Cinema 4D</div>
                                        <p>共70节课程 </p>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <div class="show-more" @click="showMore=showMore == 0 ? 1 : 0">
                            {{showMore == 0?'展开全部':'收起部分'}} <span :class="showMore == 0?'allow-up':'allow-down'"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="floorWrapper">
                <div class="floor">
                    <div class="floorTitle">
                        <div class="floorTitleLeft">
                            <div class="title">畅销课程</div>
                            <div class="desc">共2W+课程</div>
                        </div>
                        <router-link class="floorTitleRight" style="display:block;"
                            :to="{name:'list',query:{is_lesson:1}}">查看更多课程 ></router-link>
                    </div>
                    <div class="educationFloorsWrap cx">
                        <router-link class="floor-item" to="/lessonDetail">
                            <div class="listImg">
                                <img src="https://image6.znzmo.com/31a05c17f8ea48cd9bd2d1f943e9c37a.jpg?x-oss-process=style/eduList"
                                    class="imgCon" alt="">
                                <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt="">
                                <div class="time">52节</div>
                            </div>
                            <div class="listInfo">
                                <div class="listInfoTitle">系列课 | SketchUp2021+Ens3.1建模渲染漫游动画教程</div>
                                <div class="keInfo">
                                    <div>空间渲染</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">1.3w
                                    </div>
                                </div>
                            </div>
                        </router-link>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://image6.znzmo.com/aBBtj3TP2a.jpg?x-oss-process=style/eduList"
                                    class="imgCon" alt="">
                                <!-- <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt=""> -->
                                <div class="time">142节</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">系列课 | Enscape 3.3 for SU 入门到提升系统教程</a>
                                <div class="keInfo">
                                    <div>Enscape</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">4.6w
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://image6.znzmo.com/6e6ecc35c76318e2f50f29cf935b1f0c.jpg?x-oss-process=style/eduList"
                                    class="imgCon" alt="">
                                <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt="">
                                <div class="time">49节</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">系列课 | 2022 3dsMax零基础教程</a>
                                <div class="keInfo">
                                    <div>3DMax</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">6847
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://image6.znzmo.com/64427ea57737cf6e150b9b62e5358d78.jpg?x-oss-process=style/eduList"
                                    class="imgCon" alt="">
                                <!-- <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt=""> -->
                                <div class="time">23节</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">系列课 | Enscape 建筑写实高级渲染课程</a>
                                <div class="keInfo">
                                    <div>建筑渲染</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">3.3w
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://image6.znzmo.com/a37a488448b55658fa57edd4c2d703fd.png?x-oss-process=style/eduList"
                                    class="imgCon" alt="">
                                <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt="">
                                <div class="time">24节</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">系列课 | 方案精研班 横扫1000张平面精髓</a>
                                <div class="keInfo">
                                    <div>其它理论</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">1922
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://image6.znzmo.com/c97b7ace881827b83c1bd80f8c76de6e.png?x-oss-process=style/eduList"
                                    class="imgCon" alt="">
                                <!-- <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt=""> -->
                                <div class="time">12节</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">系列课 | Enscape建筑写实渲染课</a>
                                <div class="keInfo">
                                    <div>建筑渲染</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">3494
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://image6.znzmo.com/ffa975afc4b3c5647e0c3acd7fa2fbc5.png?x-oss-process=style/eduList"
                                    class="imgCon" alt="">
                                <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt="">
                                <div class="time">27节</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">系列课 | CAD施工图识图及彩平绘制技巧</a>
                                <div class="keInfo">
                                    <div>户型优化</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">1949
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://image6.znzmo.com/6908d2524838d42db7c5bd562fd41462.png?x-oss-process=style/eduList"
                                    class="imgCon" alt="">
                                <!-- <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt=""> -->
                                <div class="time">8节</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">系列课 | D5渲染器官方新手教程</a>
                                <div class="keInfo">
                                    <div>D5 Render</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">576
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://image6.znzmo.com/1656383455126_8545.jpeg?x-oss-process=style/eduList"
                                    class="imgCon" alt="">
                                <!-- <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt=""> -->
                                <div class="time">4分</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">ENSCAPE渲染初识及环境体积光</a>
                                <div class="keInfo">
                                    <div>Enscape</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">587
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://image6.znzmo.com/4bfccc5fea30c2e0ec729def3fe49f18.jpg?x-oss-process=style/eduList"
                                    class="imgCon" alt="">
                                <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt="">
                                <div class="time">29分16秒 </div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">提高渲染质感的高级技巧 客厅渲染案例</a>
                                <div class="keInfo">
                                    <div>空间渲染</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">261
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="floorWrapper mT0" style="display: none;">
                <div class="floor">
                    <div class="floorTitle">
                        <div class="floorTitleLeft">
                            <div class="title">最新课程</div>
                            <div class="desc">近一周更新58节</div>
                        </div>
                        <div class="floorTitleRight">
                            查看更多课程 >
                        </div>
                    </div>
                    <div class="educationFloorsWrap">
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://image6.znzmo.com/e06b21f2b9b9e22ad90873d08a327776.jpg?x-oss-process=style/eduList"
                                    class="imgCon" alt="">
                                <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt="">
                                <div class="time">20分30秒</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">切割木工中最坚硬的接头-交叉半膝斜接</a>
                                <div class="keInfo">
                                    <div>施工工艺</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png" alt="">3
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://image6.znzmo.com/13ad0d64ec0951c9548375fd5fad5226.jpg?x-oss-process=style/eduList"
                                    class="imgCon" alt="">
                                <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt="">
                                <div class="time">16分2秒</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">如何建造挡土墙</a>
                                <div class="keInfo">
                                    <div>施工工艺</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">13
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://image6.znzmo.com/eRZTYeapeF.png?x-oss-process=style/eduList"
                                    class="imgCon" alt="">
                                <!-- <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt=""> -->
                                <div class="time">8节</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">系列课 | 景观软件 sketchup进阶之路</a>
                                <div class="keInfo">
                                    <div>景观建模</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">84
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://image6.znzmo.com/k2ijf4bQGD.png?x-oss-process=style/eduList"
                                    class="imgCon" alt="">
                                <!-- <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt=""> -->
                                <div class="time">17节</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">系列课 | 景观设计师都需要做什么？</a>
                                <div class="keInfo">
                                    <div>景观知识</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">689
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://image6.znzmo.com/xSzjjdtpP7.png?x-oss-process=style/eduList"
                                    class="imgCon" alt="">
                                <!-- <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt=""> -->
                                <div class="time">30节</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">系列课 | 2022年时尚住宅美学精讲</a>
                                <div class="keInfo">
                                    <div>其它趋势</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">291
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://image6.znzmo.com/7wKF8C63hb.jpg?x-oss-process=style/eduList"
                                    class="imgCon" alt="">
                                <!-- <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt=""> -->
                                <div class="time">41节</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">系列课 | Corona8.2渲染全套系统特训</a>
                                <div class="keInfo">
                                    <div>Corona</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">1929
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://image6.znzmo.com/4cJfanttid.png?x-oss-process=style/eduList"
                                    class="imgCon" alt="">
                                <!-- <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt=""> -->
                                <div class="time">32节</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">系列课 | VRay6.06高级写实渲染教程</a>
                                <div class="keInfo">
                                    <div>Vray for 3DMax</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">128
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://image6.znzmo.com/zAHRspkMYa.jpg?x-oss-process=style/eduList"
                                    class="imgCon" alt="">
                                <!-- <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt=""> -->
                                <div class="time">22节</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">系列课 | Corona8.2进阶渲染教程</a>
                                <div class="keInfo">
                                    <div>Corona8</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">862
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://image6.znzmo.com/991daf8021ddb83d11c94b9befd35976.png?x-oss-process=style/eduList"
                                    class="imgCon" alt="">
                                <!-- <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt=""> -->
                                <div class="time">38分</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">系列课 | 零基础学独立绘制CAD全套规范施工图</a>
                                <div class="keInfo">
                                    <div>施工图绘制</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">1524
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://image6.znzmo.com/aef958539f12fe47e3a8158b806e4aac.png?x-oss-process=style/eduList"
                                    class="imgCon" alt="">
                                <!-- <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt=""> -->
                                <div class="time">20节</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">系列课 | 室内设计高效谈单技巧</a>
                                <div class="keInfo">
                                    <div>谈单技巧</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">680
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="floorWrapper mT0">
                <div class="floor">
                    <div class="floorTitle">
                        <div class="floorTitleLeft">
                            <div class="title">室内设计</div>
                        </div>
                        <div class="floorTitleRight">
                            查看更多课程 >
                        </div>
                    </div>
                    <div class="floorTitleNav">
                        <div class="titleNav">

                            <a href="" class="active">3DMax</a>
                            <a href="">VRay</a>
                            <a href="">AutoCAD</a>
                            <a href="">Corona</a>
                            <a href="">3DMax + VRay</a>
                            <a href="">SketchUp</a>
                        </div>
                        <div class="floorTitleRight">
                            查看更多课程 >
                        </div>
                    </div>
                    <div class="educationFloorsWrap">
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://img.3d66.com/soft/2021/20210721/4657c69b17441f16948185e12efdf034.jpg"
                                    class="imgCon" alt="">
                                <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt="">
                                <div class="time">18分13秒</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">3DMAX室内效果图全程班-零基础到独立出图就业教程</a>
                                <div class="keInfo">
                                    <div>3DMax</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png" alt="">1
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://img.3d66.com/soft/2020/20200113/f1e9507eb5dcb27c549e857f12e8fb18.jpg"
                                    class="imgCon" alt="">
                                <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt="">
                                <div class="time">130节</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">3DMax建模技巧130讲教程</a>
                                <div class="keInfo">
                                    <div>3DMax</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png" alt="">3
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://img.3d66.com/soft/2019/20191107/a0ac5e45879afdf3fb4cd4f2a075593a.jpg"
                                    class="imgCon" alt="">
                                <!-- <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt=""> -->
                                <div class="time">30节</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">零基础7天学会3DMax2019课程</a>
                                <div class="keInfo">
                                    <div>3DMax</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">291
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://img.3d66.com/zixue/202004/208/2020-04-04/aa0ce08a751ad9fa03be1f1b1d68e940.jpg"
                                    class="imgCon" alt="">
                                <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt="">
                                <div class="time">5节</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">3DMax零基础入门到精通实例教程</a>
                                <div class="keInfo">
                                    <div>3DMax</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">62
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://img.3d66.com/soft/2019/20191112/94e107d168d1b42c73b4497af97b8cac.jpg"
                                    class="imgCon" alt="">
                                <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt="">
                                <div class="time">5节</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">3DMax展览展示项目实战教程</a>
                                <div class="keInfo">
                                    <div>3DMax</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">110
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="floorWrapper mT0">
                <div class="floor">
                    <div class="floorTitle">
                        <div class="floorTitleLeft">
                            <div class="title">建筑景观</div>
                            <!-- <div class="desc">近一周更新58节</div> -->
                        </div>
                        <div class="floorTitleRight">
                            查看更多课程 >
                        </div>
                    </div>
                    <div class="floorTitleNav">
                        <div class="titleNav">
                            <!-- <a href="">推荐</a> -->
                            <a href="">SketchUp</a>
                            <a href="">3DMax</a>
                            <a href="">VRay</a>
                            <a href="">Rhino</a>
                            <a href="">Enscape</a>
                            <a href="">VRay 5.0</a>
                        </div>
                        <div class="floorTitleRight">
                            查看更多课程 >
                        </div>
                    </div>
                    <div class="educationFloorsWrap">
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://image6.znzmo.com/68ea7d489590fe7be1ef610fc13e8156.jpg?x-oss-process=style/eduList"
                                    class="imgCon" alt="">
                                <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt="">
                                <div class="time">8分16秒</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">从谷歌地图下载城市和景观3D模型并导入到犀牛</a>
                                <div class="keInfo">
                                    <div>3DMAX</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png" alt="">2
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://image6.znzmo.com/1659516176774_5151.jpeg?x-oss-process=style/eduList"
                                    class="imgCon" alt="景观效果图">
                                <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt="">
                                <div class="time">24分34秒</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">街道扁平风鸟瞰图01 扁平风格优势及国外su素材查找 添加 </a>
                                <div class="keInfo">
                                    <div>景观效果图</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">33
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://image6.znzmo.com/eRZTYeapeF.png?x-oss-process=style/eduList"
                                    class="imgCon" alt="系列课 | 景观软件 sketchup进阶之路">
                                <!-- <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt=""> -->
                                <div class="time">8节</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">系列课 | 景观软件 sketchup进阶之路</a>
                                <div class="keInfo">
                                    <div>SketchUp</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">84
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://image6.znzmo.com/k2ijf4bQGD.png?x-oss-process=style/eduList"
                                    class="imgCon" alt="系列课 | 景观设计师都需要做什么？">
                                <!-- <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt=""> -->
                                <div class="time">17节</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">系列课 | 景观设计师都需要做什么？</a>
                                <div class="keInfo">
                                    <div>景观知识</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">689
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://image6.znzmo.com/1659513618525_3639.jpeg?x-oss-process=style/eduList"
                                    class="imgCon" alt="城市街道景观彩平图（上） vary渲染周边环境及调整">
                                <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt="">
                                <div class="time">18分50秒</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle"> 城市街道景观彩平图（上） vary渲染周边环境及调整</a>
                                <div class="keInfo">
                                    <div>VRay</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">18
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="floorWrapper mT0">
                <div class="floor">
                    <div class="floorTitle">
                        <div class="floorTitleLeft">
                            <div class="title">平面设计</div>
                            <!-- <div class="desc">近一周更新58节</div> -->
                        </div>
                        <div class="floorTitleRight">
                            查看更多课程 >
                        </div>
                    </div>
                    <div class="floorTitleNav">
                        <div class="titleNav">
                            <!-- <a href="">推荐</a> -->
                            <a href="">Photoshop</a>
                            <a href="">SAI</a>
                            <a href="">IPAD手绘</a>
                            <a href="">Adobe illustrator</a>
                            <a href="">Cinema 4D</a>
                            <a href="">CorelDraw</a>
                        </div>
                        <div class="floorTitleRight">
                            查看更多课程 >
                        </div>
                    </div>
                    <div class="educationFloorsWrap">
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://img.3d66.com/soft/2020/20201013/807daf2fcf3ab8f7965a0def74f917d1.jpg"
                                    class="imgCon" alt="Photoshop2020平面设计全套课">
                                <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt="">
                                <div class="time">10节</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">Photoshop2020平面设计全套课</a>
                                <div class="keInfo">
                                    <div>Photoshop</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png" alt="">2
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://img.3d66.com/soft/2019/20190926/95d9e0c3f29f0ccc398bc889b2fa615b.jpg"
                                    class="imgCon" alt="PS淘宝美工全套电商平面设计小白零基础视频课程">
                                <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt="">
                                <div class="time">15节</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">PS淘宝美工全套电商平面设计小白零基础视频课程</a>
                                <div class="keInfo">
                                    <div>Photoshop</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">33
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://img.3d66.com/soft/2019/20190822/666f68e046472bc3003ff47302eaf0f0.jpg"
                                    class="imgCon" alt="Photoshop从入门到精通教程">
                                <!-- <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt=""> -->
                                <div class="time">8节</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">Photoshop从入门到精通教程</a>
                                <div class="keInfo">
                                    <div>Photoshop</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">84
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://img.3d66.com/soft/2020/20200107/5e703725f30e43e5e5b789218d49084c.jpg"
                                    class="imgCon" alt="Photoshop CC2017淘宝美工平面UI设计零基础入门教程">
                                <!-- <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt=""> -->
                                <div class="time">17节</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">Photoshop CC2017淘宝美工平面UI设计零基础入门教程</a>
                                <div class="keInfo">
                                    <div>Photoshop</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">689
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://img.3d66.com/soft/2019/20190829/71dca8867f2107ca11ad716840ad11f7.jpg"
                                    class="imgCon" alt="PS零基础入门到精通教程">
                                <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt="">
                                <div class="time">5节</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">PS零基础入门到精通教程</a>
                                <div class="keInfo">
                                    <div>Photoshop</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">18
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="floorWrapper mT0">
                <div class="floor">
                    <div class="floorTitle">
                        <div class="floorTitleLeft">
                            <div class="title">视频制作</div>
                            <!-- <div class="desc">近一周更新58节</div> -->
                        </div>
                        <div class="floorTitleRight">
                            查看更多课程 >
                        </div>
                    </div>
                    <div class="floorTitleNav">
                        <div class="titleNav">
                            <!-- <a href="">推荐</a> -->
                            <a href="">After Effects</a>
                            <a href="">Premiere</a>
                            <a href="">短视频</a>
                            <a href="">3DMax</a>
                            <a href="">Unity3D</a>
                            <a href="">Cinema 4D</a>
                        </div>
                        <div class="floorTitleRight">
                            查看更多课程 >
                        </div>
                    </div>
                    <div class="educationFloorsWrap">
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://img.3d66.com/soft/2021/20210428/853d3dd6632e8575c1ae0bd962c9cfa7.jpg"
                                    class="imgCon" alt="C4D入门学习手册">
                                <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt="">
                                <div class="time">45节</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">C4D入门学习手册</a>
                                <div class="keInfo">
                                    <div>Cinema 4D</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png" alt="">2
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://img.3d66.com/soft/2019/20190614/20188fd5887fe0eab28ae9551fdabf5f.png"
                                    class="imgCon" alt="">
                                <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt="">
                                <div class="time">60节</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">Premiere CC2018影视后期视频剪辑制作制作抖音快手特效教程</a>
                                <div class="keInfo">
                                    <div>Premiere</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">33
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://img.3d66.com/soft/2019/20190614/d1103c7788c197ee49e1d14f8cb530dc.jpg"
                                    class="imgCon" alt="">
                                <!-- <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt=""> -->
                                <div class="time">10节</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">小白变身老司机短视频拍摄系统课程</a>
                                <div class="keInfo">
                                    <div>短视频</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">84
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://img.3d66.com/soft/2019/20190614/6fde9a535b3a892fd55fe2f3b5cbeb22.png"
                                    class="imgCon" alt="">
                                <!-- <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt=""> -->
                                <div class="time">17节</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">AE CC2018零基础入门影视后期制作教程</a>
                                <div class="keInfo">
                                    <div>After Effects</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">6890
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://img.3d66.com/zixue/202011/293/2020-11-16/c10dc37830f89b7ffecaac07f128be68.jpg"
                                    class="imgCon" alt="">
                                <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt="">
                                <div class="time">25节</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">AE软件视频教程（零基础到精通）</a>
                                <div class="keInfo">
                                    <div>After Effects</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">18
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="floorWrapper mT0">
                <div class="floor">
                    <div class="floorTitle">
                        <div class="floorTitleLeft">
                            <div class="title">工业建模渲染</div>
                            <!-- <div class="desc">近一周更新58节</div> -->
                        </div>
                        <div class="floorTitleRight">
                            查看更多课程 >
                        </div>
                    </div>
                    <div class="floorTitleNav">
                        <div class="titleNav">
                            <!-- <a href="">推荐</a> -->
                            <a href="">Rhino</a>
                            <a href="">AutoCAD</a>
                            <a href="">UG NX</a>
                            <a href="">3DMax</a>
                            <a href="">keyShot</a>
                            <a href="">SolidWorks</a>
                        </div>
                        <div class="floorTitleRight">
                            查看更多课程 >
                        </div>
                    </div>
                    <div class="educationFloorsWrap">
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://img.3d66.com/soft/2019/20190702/7f087171c9aba4946d5a990dcc17fbfa.jpg"
                                    class="imgCon" alt="">
                                <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt="">
                                <div class="time">65节</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">3DMAX+VRAY产品全案例渲染精品课程</a>
                                <div class="keInfo">
                                    <div>3DMax</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png" alt="">2
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://img.3d66.com/soft/2020/20200731/83621b88bf47bfb2786e910b762e1e08.jpg"
                                    class="imgCon" alt="">
                                <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt="">
                                <div class="time">59分34秒</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">AutoCAD入门到精通视频教程</a>
                                <div class="keInfo">
                                    <div>AutoCAD</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">33
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://img.3d66.com/soft/2020/20200521/67436ac596dca4dec2c3870c161631d7.jpg"
                                    class="imgCon" alt="">
                                <!-- <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt=""> -->
                                <div class="time">8节</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">犀牛（Rhino）建模教程</a>
                                <div class="keInfo">
                                    <div>Rhino</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">84
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://img.3d66.com/zixue/202001/8/2020-01-30/e9f2aa63f02b5e6dec2cf1547817bed1.jpg"
                                    class="imgCon" alt="">
                                <!-- <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt=""> -->
                                <div class="time">17节</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">Auto CAD2020零基础入门到精通教程</a>
                                <div class="keInfo">
                                    <div>AutoCAD</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">689
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="floor-item">
                            <div class="listImg">
                                <img src="https://img.3d66.com/soft/2020/20200806/748756baa94dfb3cc06931f5a670aabf.png"
                                    class="imgCon" alt="">
                                <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt="">
                                <div class="time">54节</div>
                            </div>
                            <div class="listInfo">
                                <a href="" class="listInfoTitle">AutoCAD2014零基础入门到精通教程</a>
                                <div class="keInfo">
                                    <div>AutoCAD</div>
                                    <div class="view">
                                        <img src="https://cdn.code.znzmo.com/sucai/img/images/cardPlayIcon.png"
                                            alt="">18
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Swiper from "swiper";
    export default {
        data() {
            return {
                bannerList: [{
                        id: 1,
                        imgUrl: 'https://image4.znzmo.com/F2tNMdEwf8.png?x-oss-process=style',
                        title: '景观设计师必备'
                    },
                    {
                        id: 2,
                        imgUrl: 'https://image4.znzmo.com/F2tNMdEwf8.png?x-oss-process=style',
                        title: '景观设计师必备'
                    }
                ],
                mySwiper: {},
                showMore: 0,

            }
        },
        components: {

        },
        watch: {
            bannerList: {
                immediate: true,
                handler() {
                    let that = this;
                    this.$nextTick(() => {
                        this.mySwiper = new Swiper(this.$refs.homeSwiper, {
                            loop: true,
                            // autoplay: {
                            //   disableOnInteraction: false,
                            // },
                            //如果需要分页器
                            pagination: {
                                el: ".swiper-pagination",
                                clickable: true,
                                type: 'custom', //自定义分页器
                                renderCustom: function (swiper, current, total) {
                                    var paginationHtml = " ";
                                    for (var i = 0; i < total; i++) {
                                        // 判断是不是激活焦点，是的话添加active类，不是就只添加基本样式类
                                        if (i === (current - 1)) {
                                            paginationHtml +=
                                                '<span class="swiper-pagination-bullet swiper-pagination-bullet-active" >' +
                                                that.bannerList[i].title + '</span>';
                                        } else {
                                            paginationHtml +=
                                                '<span class="swiper-pagination-bullet" >' + that
                                                .bannerList[i].title + '</span>';
                                        }
                                    }
                                    return paginationHtml;
                                },

                            },
                            //如果需要前进后退按钮
                            // navigation: {
                            //   nextEl: ".swiper-button-next",
                            //   prevEl: ".swiper-button-prev",
                            // },
                            on: {
                                slideChangeTransitionEnd: function () {
                                    // alert(this.activeIndex); //切换结束时，告诉我现在是第几个slide
                                    console.log(this.activeIndex)
                                },
                            },
                        });
                        console.log(this.mySwiper)
                    });
                },
            },
        },
    };
</script>

<style lang="less" scoped>
    .sec-banner {
        position: relative;
        background-color: #999;
        overflow: hidden;
        width: 100%;
        max-width: 1920px;
        height: 470px;
        margin: 0 auto;

        img {
            width: 100%;
            height: 470px;
            -o-object-fit: cover;
            object-fit: cover;
        }

        .slick-dots {
            position: absolute;
            width: 100%;
            height: 36px;
            bottom: 0px;
            background: rgba(0, 0, 0, 0.6);
            display: flex;
            -ms-flex-pack: distribute;
            justify-content: space-around;
            -webkit-box-align: center;
            -moz-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            z-index: 9;

            li {
                position: relative;
                display: inline-block;
                margin: 0 2px;
                padding: 0;
                text-align: center;
                vertical-align: top;
                -webkit-box-flex: 1;
                -moz-box-flex: 1;
                -ms-flex: 1;
                flex: 1;
                height: 100%;
                font-size: 14px;

                font-weight: 400;
                color: rgba(255, 255, 255, 0.6);
                line-height: 36px;
                cursor: pointer;
            }

            .slick-active {
                color: #fff;
                font-weight: 500;
            }
        }
    }

    .imgListWrapper {
        width: 100%;
        padding: 0 24px;
        margin-top: 6px;
        position: relative;
        height: 72px;
        justify-content: center;
        box-shadow: none;

        .centerSolid {
            display: flex;
            justify-content: center;
            height: 100%;

            .singleWrap {
                width: 140px;
                height: 100%;
                border-radius: 8px;
                overflow: hidden;
                margin-right: 12px;
                position: relative;

                .mask {
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, .4);
                    font-size: 18px;

                    font-weight: 500;
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    transition: background-color .2s;
                    position: relative;
                    z-index: 3;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
    }

    .course {
        padding: 25px 20px;
        background-color: #fff;
        border-radius: 8px;
        margin-bottom: 30px;
        margin-top: 20px;

        .adv-row {
            display: flex;

            .row-col {
                width: 50%;
                margin-bottom: 26px;
                border-right: 1px dashed #e3e3e3;
                padding-right: 24px;

                .row-title {
                    display: flex;

                    h3 {
                        font-size: 16px;
                        font-weight: 700;
                        color: #333;
                    }
                }

                .row-list {
                    margin-top: 5px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    li {
                        margin: 10px 0 0 0;
                        height: 26px;
                        line-height: 26px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        cursor: pointer;

                        a {
                            display: inline-block;
                            width: 132px;
                            font-size: 13px;
                            color: #666;
                            text-align: center;
                            border-radius: 2px;
                            background: #f9f9f9;

                            &:hover {
                                background: #e80000;
                                color: #fff;
                            }
                        }
                    }
                }

                .skill {
                    background-position: -49px -10px;
                }

                .cour {
                    background-position: -10px -10px;
                }
            }

            .row-col:last-child {
                padding-right: 0;
                border-right: none;
                margin-left: 24px;
            }

            .adv-icon {
                margin-right: 4px;
                display: inline-block;
                width: 18px;
                height: 18px;
                background: url(https://static.3d66.com/zixuev2/images/adv-icon.png) no-repeat;
            }
        }

        .recommed {
            display: flex;

            a {
                width: 336px;
                height: 112px;
                // border-radius: 8px;
                overflow: hidden;
                margin-right: 20px;

                img {
                    width: 100%;
                    height: 100%;
                    transition: all .3s;

                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }

            a:last-child {
                margin-right: 0;
            }
        }

        .educationFloorsWrap {
            overflow: hidden;

            .floor-item {
                width: 336px;
                margin-bottom: 0;

                .listInfo {
                    background-color: #fafafa;
                    display: none;

                    .keInfo {
                        display: none;
                    }
                }

                .listImg {
                    .imgR {
                        display: none;
                    }

                    .time {
                        display: none;
                    }
                }
            }
        }
    }

    .soft-lists-wrap {
        position: relative;

        .soft-list {
            min-height: 110px;
            overflow: hidden;

            li {
                margin-left: 20px;
                margin-top: 10px;
                margin-bottom: 10px;
                width: 240px;
                height: 90px;
                // border-radius: 4px;
                float: left;
                background: hsla(0, 0%, 89.4%, .4);
                cursor: pointer;
                -webkit-transition: .5s;
                transition: .5s;

                a {
                    padding: 14px 15px;
                    width: 100%;
                    height: 100%;
                    color: #333;
                    display: flex;

                    img {
                        width: 60px;
                        height: 60px;
                        border-radius: 4px;
                    }

                    .txt {
                        margin: 6px 10px 10px 18px;
                        min-width: 90px;
                        font-size: 16px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    p {
                        font-size: 12px;
                        color: #666;
                        margin-left: 18px;
                    }
                }

                &:hover {
                    box-shadow: 1px 3px 6px 0 rgb(181 181 182 / 50%);
                }
            }

            li:nth-child(7n + 1) {
                margin-left: 0;
            }
        }

        .show-more {
            font-size: 14px;
            color: #999;
            cursor: pointer;
            text-align: center;
            margin-top: 10px;

            .allow-up {
                border: solid #999;
                border-width: 0 1px 1px 0;
                display: inline-block;
                padding: 3px;
                margin-left: 5px;
                vertical-align: 3px;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                position: relative;
            }

            .allow-down {
                border: solid #999;
                border-width: 0 1px 1px 0;
                display: inline-block;
                padding: 3px;
                margin-left: 5px;
                vertical-align: 3px;
                -webkit-transform: rotate(-135deg);
                transform: rotate(-135deg);
                position: relative;
                top: 3px;
            }
        }
    }

    .floorWrapper {
        margin-top: 48px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .floor {
            margin-bottom: 28px;
            width: 100%;

            .floorTitle {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .floorTitleLeft {
                    display: flex;
                    align-items: baseline;

                    .title {
                        height: 34px;
                        font-size: 24px;

                        font-weight: 500;
                        color: #333;
                        line-height: 34px;
                    }

                    .desc {
                        margin-left: 24px;
                        height: 24px;
                        font-size: 16px;

                        color: #999;
                        line-height: 24px;
                    }
                }

                .floorTitleRight {
                    height: 24px;
                    font-size: 16px;
                    display: none;
                    color: #333;
                    line-height: 24px;

                    i {
                        margin-left: 4px;
                        display: inline-block;
                        font-size: 12px;
                        vertical-align: middle;
                    }
                }
            }

            .floorTitleNav {
                margin-top: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .titleNav {
                    display: flex;
                    align-items: center;

                    a {
                        width: 128px;
                        height: 26px;
                        background: #eaeaea;
                        border: 1px solid #e3e3e3;
                        border-radius: 3px;
                        text-align: center;
                        line-height: 26px;
                        font-size: 14px;
                        color: #666;
                        margin-right: 15px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        cursor: pointer;
                    }

                    a.active {
                        color: #fff;
                        background-color: #e80000;
                    }

                    a:last-child {
                        margin-right: 0;
                    }
                }

                .floorTitleRight {
                    height: 24px;
                    font-size: 14px;
                    cursor: pointer;
                    color: #666;
                    line-height: 24px;

                    i {
                        margin-left: 4px;
                        display: inline-block;
                        font-size: 12px;
                        vertical-align: middle;
                    }
                }
            }

        }
    }

    .educationFloorsWrap {
        width: 100%;
        text-align: left;
        margin-top: 24px;

        .floor-item {
            position: relative;
            width: 344px;
            margin-right: 20px;
            margin-bottom: 20px;
            float: left;
            border-radius: 8px;
            overflow: hidden;

            .listImg {
                cursor: pointer;
                width: 100%;
                height: 230px;
                border-radius: 8px 8px 0 0;
                overflow: hidden;
                position: relative;

                .imgCon {
                    width: 100%;
                    height: 100%;
                    -webkit-transition: all 1s;
                    transition: all 1s;

                    &:hover {
                        transform: scale(1.1);
                    }
                }

                .imgR {
                    width: 82px;
                    height: 28px;
                    position: absolute;
                    right: 0px;
                    top: 0px;
                    transform: scale(1);
                }

                .time {
                    position: absolute;
                    right: 10px;
                    bottom: 10px;
                    padding: 0 8px;
                    height: 22px;
                    font-size: 12px;

                    font-weight: 400;
                    color: #fff;
                    line-height: 22px;
                    background: rgba(0, 0, 0, 0.6);
                    border-radius: 11px;
                }
            }

            .listInfo {
                background: #fff;
                padding: 20px;
                cursor: pointer;

                .listInfoTitle {
                    cursor: pointer;
                    height: 25px;
                    line-height: 25px;
                    font-size: 18px;

                    font-weight: 600;
                    color: #333;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    -webkit-transition: all 0.3s;
                    transition: all 0.3s;
                    display: inline-block;
                    width: 100%;
                }

                .keInfo {
                    margin-top: 16px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 20px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #999;
                    line-height: 20px;

                    .view {
                        display: flex;
                        align-items: center;

                        img {
                            margin-right: 4px;
                        }
                    }
                }
            }
        }

        .floor-item:nth-child(5n) {
            margin-right: 0;
        }
    }

    .mT0 {
        margin-top: 0;
    }

    @media only screen and (max-width: 1800px) {
        .course {
            .adv-row .row-col .row-list li:nth-child(n+16) {
                display: none;
            }

            .educationFloorsWrap .floor-item {
                width: 276px;
            }
        }

        .educationFloorsWrap {
            .floor-item {
                width: 284px;

                .listImg {
                    height: 190px;
                }
            }
        }

        .soft-lists-wrap {
            .soft-list {
                max-height: 110px;

                li {
                    margin-left: 12px;
                }

                li:nth-child(7n + 1) {
                    margin-left: 12px;
                }

                li:nth-child(6n + 1) {
                    margin-left: 0px;
                }
            }
        }
    }

    @media only screen and (max-width: 1500px) {
        .course {
            .adv-row .row-col .row-list li:nth-child(n+16) {
                display: none;
            }

            .adv-row .row-col .row-list li:nth-child(n+13) {
                display: none;
            }

            .educationFloorsWrap .floor-item {
                width: 224px;
                margin-right: 10px;
            }

            .educationFloorsWrap .floor-item:nth-child(5n) {
                margin-right: 0;
            }
        }

        .educationFloorsWrap {
            .floor-item {
                width: 232px;
                margin-right: 10px;

                .listImg {
                    height: 155px;
                }

                .listInfo {
                    padding: 16px;

                    .listInfoTitle {
                        font-size: 14px;
                    }
                }
            }

            .floor-item:nth-child(5n) {
                margin-right: 0;
            }
        }

        .soft-lists-wrap {
            .soft-list {
                max-height: 110px;

                li {
                    margin-left: 12px;
                    width: 230px;
                }

                li:nth-child(7n + 1) {
                    margin-left: 12px;
                }

                li:nth-child(6n + 1) {
                    margin-left: 12px;
                }

                li:nth-child(5n + 1) {
                    margin-left: 0px;
                }
            }
        }
    }

    // @media only screen and (max-width: 1000px) {
    //     .sec-banner {
    //         height: 300px;

    //         img {
    //             height: 300px;
    //         }

    //         .slick-dots {
    //             display: none;
    //         }
    //     }

    //     .course .educationFloorsWrap {
    //         .floor-item {
    //             width: calc(50% - 5px);
    //             margin-bottom: 10px;
    //             border-radius: 4px;

    //             .listImg {
    //                 height: 110px;
    //                 border-radius: 0;
    //             }
    //         }

    //         .floor-item:nth-child(2n) {
    //             margin-right: 0;
    //         }

    //         .floor-item:last-child {
    //             display: none;
    //         }
    //     }

    //     .soft-lists-wrap .soft-list {
    //         height: 175px;

    //         li {
    //             width: calc(50% - 5px);
    //             margin-left: 10px;
    //             margin-top: 5px;
    //             margin-bottom: 5px;
    //             height: auto;

    //             a {
    //                 padding: 8px;

    //                 .txt {
    //                     min-width: unset;
    //                     margin: 0;
    //                     margin-bottom: 10px;
    //                     width: 90px;
    //                 }

    //                 p {
    //                     margin-left: 0px;
    //                 }

    //                 &>div {
    //                     flex: 1;
    //                     padding-left: 8px;
    //                     padding-top: 6px;
    //                 }
    //             }
    //         }

    //         li:nth-child(5n + 1) {
    //             margin-left: 10px;
    //         }

    //         li:nth-child(2n+1) {
    //             margin-left: 0;
    //         }
    //     }

    //     .floorWrapper .floor .floorTitle .floorTitleLeft .desc {
    //         display: none;
    //     }

    //     .floorWrapper .floor .floorTitleNav {
    //         display: none;
    //     }

    //     .floorWrapper .floor .floorTitle .floorTitleRight {
    //         display: block;
    //     }

    //     .educationFloorsWrap {
    //         overflow: hidden;

    //         .floor-item {
    //             width: calc(50% - 5px);
    //             margin-bottom: 10px;
    //             border-radius: 4px;

    //             .listImg {
    //                 height: auto;
    //             }

    //             .listInfo {
    //                 padding: 8px;

    //                 .keInfo {
    //                     margin-top: 8px;
    //                 }
    //             }
    //         }

    //         .floor-item:last-child {
    //             display: none;
    //         }

    //         .floor-item:nth-child(5n) {
    //             margin-right: 10px;
    //         }

    //         .floor-item:nth-child(2n) {
    //             margin-right: 0;
    //         }
    //     }

    //     .cx {
    //         .floor-item:last-child {
    //             display: block;
    //         }
    //     }
    // }
</style>